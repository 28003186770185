<template>
    <div>
      <!-- modal form -->
      <b-modal
        id="modal-form"
        ok-only
        centered
        size="md"
        content-class="shadow"
        hide-footer
      >
      <template #modal-title> <div style="font-size:24px; font-weight:600;">{{ createMode ? 'Tambah' : 'Ubah'}} Sengketa</div></template>
        <div >
          <b-form-row>
            <b-col>
              <input style="display: none;" @change="uploadFile" type="file" ref="fileInput"/>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Nama Ormas</p>
                </b-col>:<b-col sm="7">
                  <!-- <b-form-input type="text" v-model="form.data_ormas_id">
                  </b-form-input> -->
                  <!-- <b-form-select style="max-width: 478px;" v-model="form.data_ormas_id">
                    <b-form-select-option value="" disabled>Silahkan Pilih Ormas</b-form-select-option>
                    <b-form-select-option :value="item.id" v-for="(item,index) in listOrmas" :key="index">{{item.nama_ormas}}</b-form-select-option>
                  </b-form-select> -->
                  <model-select :options="options"
                      v-model="form.data_ormas_id"
                      placeholder="select item">
                  </model-select>
                </b-col>
              </b-row>
              <!-- <b-row class="mb-5 d-flex align-items-center" v-if="createMode">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Status Sengketa</p>
                </b-col>:<b-col sm="7">
                  <b-form-checkbox
                  value="0"
                  v-model="form.status_sengketa"
                  unchecked-value="1"
                >
                  Sedang Berlangsung
                </b-form-checkbox>
                </b-col>
              </b-row> -->
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Tanggal Laporan</p>
                </b-col>:<b-col sm="7">
                  <b-form-datepicker v-model="form.tanggal_sengketa" id="tgllaporan" class="mb-2"></b-form-datepicker>
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Sumber Laporan</p>
                </b-col>:<b-col sm="7">
                  <b-form-input v-model="form.sumber_laporan" type="text">
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Keterangan</p>
                </b-col>:<b-col sm="7">
                  <b-form-textarea
                    placeholder="Enter something..."
                    rows="3"
                    v-model="form.keterangan"
                    max-rows="6"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Unggah File Laporan</p>
                </b-col>:<b-col sm="7">
                  <b-button v-if="form.file_laporan === ''" variant="primary" size="sm" @click="fileClicked()"><v-icon>mdi-file-plus</v-icon> Unggah FIle</b-button>
                  <a v-if="form.file_laporan !== ''" :href="fileURL + form.file_laporan" target="_blank" type="button">
                  <b-button variant="success" size="sm">
                    Lihat File
                  </b-button>
                  </a>
                  <b-button v-if="form.file_laporan !== ''" @click="form.file_laporan = ''" variant="danger" size="sm" class="ml-3">
                    <v-icon class="p-0">mdi-delete-outline</v-icon>
                  </b-button>
                </b-col>
              </b-row>             
            </b-col>
          </b-form-row>
          <div class="text-center">
            <b-button
              variant="outline-primary"
              class="mt-16 text-center mr-5"
              @click="closeForm()"
            >
              Batal
            </b-button>
            <b-button
              variant="primary"
              class="mt-16 text-center"
              @click="submit()"
            >
              Simpan
            </b-button>
            <b-button
            v-if="!createMode"
              variant="danger"
              class="mt-16 text-center ml-5"
              @click="endSengketaForm()"
            >
              Sengketa Berakhir
            </b-button>
          </div>
        </div>
      </b-modal>

      <!-- modal filter -->
      <b-modal
          id="filter"
          ok-only
          centered
          size="md"
          content-class="shadow"
          hide-footer
        >
        <template #modal-title> <div style="font-size:24px; font-weight:600;">Filter</div></template>
          <div >
            <b-form-row>
              <b-col>
                <b-row>
                  <b-col sm="5">
                    <p style="font-weight: 600; display: none; margin:0px;">Status Sengketa</p>
                  </b-col>:<b-col sm="6">
                      <b-form-select v-model="filter.status_sengketa" style="max-width: 478px;">
                        <b-form-select-option :value="''">Semua</b-form-select-option>
                        <b-form-select-option :value="0">Sedang Berlangsung</b-form-select-option>
                        <b-form-select-option :value="1">Selesai</b-form-select-option>
                      </b-form-select>
                  </b-col>
                </b-row>
                <b-row class="mt-5 d-flex align-items-center">
                  <b-col sm="5">
                    <p style="font-weight: 600; margin:0px;">Tanggal Laporan</p>
                  </b-col>:<b-col sm="6">
                    <b-form-datepicker v-model="filter.tanggal_sengketa" id="tgllaporan" class="mb-2"></b-form-datepicker>
                  </b-col>
                </b-row>        
              </b-col>
            </b-form-row>
            <div class="text-center">
              <b-button
                variant="outline-primary"
                class="mt-16 text-center mr-5"
                @click="$bvModal.hide('filter')"
              >
                Batal
              </b-button>
              <b-button
                variant="primary"
                class="mt-16 text-center"
                @click="submitFilter()"
              >
                Terapkan
              </b-button>
            </div>
          </div>
      </b-modal>

      <!-- modal end sengketa -->
      <b-modal
          id="end-sengketa"
          ok-only
          centered
          size="md"
          content-class="shadow"
          hide-footer
          hide-header
        >
        <!-- <template #modal-title> <div style="font-size:24px; font-weight:600;">Filter</div></template>   -->
          <div >
            <b-form-row>
              <b-col>
                <b-row class="mt-5 d-flex align-items-center">
                  <b-col sm="5">
                    <p style="font-weight: 600; margin:0px;">Silahkan Pilih Tanggal Berakhir Sengketa</p>
                  </b-col>:<b-col sm="6">
                    <b-form-datepicker v-model="tgl_end" id="tgllaporan" class="mb-2"></b-form-datepicker>
                  </b-col>
                </b-row>        
              </b-col>
            </b-form-row>
            <div class="text-center">
              <b-button
                variant="outline-primary"
                class="mt-16 text-center mr-5"
                @click="batalEndForm()"
              >
                Batal
              </b-button>
              <b-button
                variant="primary"
                class="mt-16 text-center"
                @click="submitEndSengketa()"
              >
                Simpan
              </b-button>
            </div>
          </div>
      </b-modal>

      <!-- modal detail -->
       <b-modal
        id="modal-detail"
        ok-only
        centered
        size="md"
        content-class="shadow"
        hide-footer
      >
      <template #modal-title> <div style="font-size:24px; font-weight:600;">Detail Sengketa</div></template>
        <div v-if="detail !== null">
          <b-form-row>
            <b-col>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Nama Ormas</p>
                </b-col>:<b-col sm="7"> {{ detail.dataOrmas.nama_ormas }}
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Status Sekretariat</p>
                </b-col>:<b-col sm="7"> {{ detail.dataOrmas.masterLokasiSekretariat.nama_lokasi }}
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Tanggal Laporan</p>
                </b-col>:<b-col sm="7">
                  {{ detail.tanggal_sengketa | moment('DD-MM-YYYY')}}
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Sumber Laporan</p>
                </b-col>:<b-col sm="7">
                  {{ detail.sumber_laporan}}
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Keterangan</p>
                </b-col>:<b-col sm="7">
                  {{ detail.keterangan}}
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">Tanggal Berakhir</p>
                </b-col>:<b-col sm="7">
                  {{detail.tanggal_berakhir  | moment('DD-MM-YYYY')}}
                </b-col>
              </b-row>
              <b-row class="mb-5 d-flex align-items-center" v-if="detail.file_laporan !== null && detail.file_laporan !== ''">
                <b-col sm="4">
                  <p style="font-weight: 600; margin:0px;">File Laporan</p>
                </b-col>:<b-col sm="7">
                  <a :href="fileURL + detail.file_laporan" target="_blank" type="button">
                  <b-button variant="success" size="sm">
                    Lihat File
                  </b-button>
                  </a>
                </b-col>
              </b-row>             
            </b-col>
          </b-form-row>
        </div>
      </b-modal>
      <b-row>
        <b-col cols="12">
          <!-- <b-card class="shadow" id="search-card"> -->
            <b-row>
            <b-col cols="11">
              <!-- <div class="form-row"> -->
                <div class="d-flex justify-content-between align-items-center p-1 pr-3" style="border-radius:8px; border:solid 1px;">
                  <input
                    type="text"
                    class="form-control border-0"
                    :placeholder="'Cari...'"
                    v-model="search"
                    v-on:keyup.enter="searchSubmit()"
                  />
                  <b-button
                    size="sm"
                    style="width:80px;margin-right:10px;"
                    variant="primary"
                    @click="searchSubmit()"
                  >
                    <v-icon
                      style="color:white;"
                      small
                    >mdi-magnify</v-icon>
                    Cari
                  </b-button>
                </div>
                
              <!-- </div> -->
            </b-col>
            <b-col cols="1" class="d-flex align-items-center p-0">
              <b-button
                size="sm"
                variant="primary"
                @click="showFilter()"
              >
                <v-icon
                  style="color:white;"
                  small
                >mdi-filter</v-icon>
                Filter
              </b-button>
            </b-col>
          </b-row>
          <!-- </b-card> -->
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col class="d-flex align-items-center justify-content-between">
          <span style="font-size:16px; font-weight:bold;">Sengketa</span>
          <div>
            <button
            :disabled="loadExExcel"
            @click="cetakSengketa('excel')"
              class="btn font-weight-bolder font-size-sm mr-3 bg-hitam"
              style="color:white;"
              data-wizard-type="action-submit"
            >
              <i class="fas fa-file-excel" style="color:white;"></i>Ekspor ke Excel
            </button>
            <button
            :disabled="loadExExcel"
            @click="cetakSengketa('pdf')"
              class="btn font-weight-bolder font-size-sm mr-3 bg-hitam"
              style="color:white;"
              data-wizard-type="action-submit"
            >
              <i class="fas fa-file-excel" style="color:white;"></i>Ekspor ke PDF
            </button>
            <b-button
              size="sm"
              variant="primary"
              @click="addForm()"
            >
              <v-icon
                style="color:white;"
                small
              >mdi-plus-box</v-icon>
              Tambah Sengketa
            </b-button>
          </div>
        </b-col>
      </b-row>
       <div
            class="table-responsive mt-4"
            style="border-radius:15px;"
          >
            <table
              class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-bordered
          "
              style="font-size:14px !important;"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="min-width: 50px" class="text-capitalize">Nama Ormas</th>
                  <!-- <th style="min-width: 50px" class="text-capitalize">Singkatan</th> -->
                  <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                  <th style="min-width: 50px" class="text-capitalize">Status Sengketa</th>
                  <th style="min-width: 50px" class="text-capitalize">Tanggal Laporan</th>
                  <th style="min-width: 50px" class="text-capitalize">Tanggal Berakhir</th>
                  <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <tbody v-if="paginateData.length === 0">
                  <template>
                      <tr>
                          <td colspan="6"> -- Tidak ada sengketa -- </td>
                      </tr>
                  </template>
              </tbody>
              <tbody style="background: #FFFFFF;">
                <template v-for="(item, i) in paginateData">
                  <tr v-bind:key="i">
                    <td >
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ (i+1) + ((currentPage-1)*perPage)}}</span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{ item.dataOrmas.nama_ormas}}
                      </span>
                    </td>
                    <!-- <td class="text-left">
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{ item.dataOrmas.nama_singkat }}</span>
                    </td> -->
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{ item.dataOrmas.masterLokasiSekretariat.nama_lokasi}}</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ item.statusValues }}</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ item.tanggal_sengketa | moment('DD-MM-YYYY')}}</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ item.tanggal_berakhir | moment('DD-MM-YYYY')}}</span>
                    </td>
                    <td>
                      <section>
                        <b-button
                          v-if="item.status_sengketa != 1"
                          variant="primary"
                          size="sm"
                          class="d-flex justify-content-center align-items-center"
                          style="width:30px;height:30px;margin:5px;"
                          @click="updateForm(item.id)"
                        >
                          <v-icon
                            style="color:white;padding:0px;"
                            small
                          >mdi-pencil-outline</v-icon>
                        </b-button>
                        <b-button
                          v-if="item.status_sengketa != 1"
                          variant="danger"
                          size="sm"
                          class="d-flex justify-content-center align-items-center"
                          style="width:30px;height:30px;margin:5px;"
                          @click="deleteSengketa(item.id)"
                        >
                          <v-icon
                            style="color:white;padding:0px;"
                            small
                          >mdi-delete-outline</v-icon>
                        </b-button>
                        <b-button
                        v-if="item.status_sengketa == 1"
                        @click="toDetail(item)"
                          class="d-flex justify-content-center align-items-center"
                          variant="success"
                          size="sm"
                          style="width:30px;height:30px;margin:5px;"
                        >
                          >>
                        </b-button>
                        </section>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="listSengketa.length"
                :per-page="perPage"
                @change="fetchData()"
                aria-controls="my-table"
              ></b-pagination>
            </b-col>
          </b-row>
    </div>
</template>
<style>
#search-card .card-body {
  padding:0px !important;
}
</style>
<script>
import Swal from "sweetalert2";
  import { ModelSelect } from 'vue-search-select'

export default {
  name: "Sengketa",
  components: {
      ModelSelect
  },
  data() {
    return {
      options: [],
      detail: null,
      createMode: true,
      currentPage: 1,
      perPage: 25,
      search: '',
      paginateData: [],
      listSengketa: [],
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL + '/',
      form: {
        "sumber_laporan": '',
        "keterangan": '',
        "status_sengketa": 0,
        "tanggal_sengketa": '',
        "data_ormas_id": '',
        "file_laporan": '',
        "tanggal_berakhir": ''
      },
      id: null,
      listOrmas: [],
      filter: {
        status_sengketa: '',
        tanggal_sengketa: ''
      },
      filterStatus: false,
      tgl_end: null,
      statEnd: false,
      userType: null,
      code: null,
      loadExExcel: false
    }
  },
  mounted(){
    this.fetchData()
    this.getUserType()
  }, 
  methods: {
    getUserType(){
      var prof = JSON.parse(localStorage.getItem('profile'))

      if(prof.profilUser && prof.profilUser.masterKesbangpol && prof.profilUser.masterKesbangpol.kabupaten_kota_kode == null){
        this.userType = 'provinsi'
        this.code = prof.profilUser.masterKesbangpol.provinsi_kode
        // this.getKota(Number(prof.profilUser.masterKesbangpol.provinsi_kode))
      }else if(prof.profilUser && prof.profilUser.masterKesbangpol && prof.profilUser.masterKesbangpol.kabupaten_kota_kode != null){
        this.userType = 'kabupaten'
        this.code = prof.profilUser.masterKesbangpol.kabupaten_kota_kode
      } else {
        this.userType = 'admin'
      }
      this.getOrmasList()
    },
    searchSubmit(){
      this.filter = {
        tanggal_sengketa: '',
        status_sengketa: ''
      }

      this.fetchData()
    },
    showFilter(){
      this.filterStatus = false
      this.$bvModal.show('filter')
    },
    submitFilter(){
      this.listSengketa = []
      this.$bvModal.hide('filter')
      this.filter.tanggal_sengketa = this.filter.tanggal_sengketa != '' ? this.filter.tanggal_sengketa + 'T00:00:00.000Z' : ''
      this.fetchData()
    },
    fetchData(){
      this.$store
      .dispatch("getListSengketa", {
        tanggal_sengketa: this.filter.tanggal_sengketa, 
        status_sengketa: this.filter.status_sengketa, 
        search: this.search
      })
      // .dispatch("getBankAccountOrganization", 4)
      .then((res) => {
        this.listSengketa = res.data.data
        this.paginate()
      })
    },
    paginate() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      this.paginateData = this.listSengketa.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
      
    },
    closeForm(){
      this.$bvModal.hide('modal-form')
      this.createMode = true
    },
    endSengketaForm(){
      
      this.$bvModal.show('end-sengketa')
      this.$bvModal.hide('modal-form')
      this.statEnd = true
    },
    batalEndForm(){
      this.statEnd = false
      this.$bvModal.hide('end-sengketa')
      this.$bvModal.show('modal-form')
    },
    resetForm(){
      this.form = {
        "sumber_laporan": '',
        "keterangan": '',
        "status_sengketa": 0,
        "tanggal_sengketa": '',
        "data_ormas_id": '',
        tanggal_berakhir: '',
        file_laporan: ''
      },
      this.tgl_end = ''
      this.statEnd = false
    },
    getOrmasList(){
      this.$store
        .dispatch("getListOrmas", {role: this.userType, kode: this.code })
        .then((res) => {
           this.$nextTick(() => {
            this.listOrmas = res.data
            for(var i = 0; i < res.data.length; i++){
              this.options.push({value: res.data[i].id , text: res.data[i].nama_ormas})
            }
          })
          })
    },
    updateForm(id){
      this.$store
      .dispatch("detailSengketa", id)
      // .dispatch("getBankAccountOrganization", 4)
      .then((res) => {
        this.id = id
        var det = res.data.data
        this.form = {
          "sumber_laporan": det.sumber_laporan,
          "keterangan": det.keterangan,
          "status_sengketa": det.status_sengketa,
          "tanggal_sengketa": det.tanggal_sengketa.substring(0,10),
          "file_laporan": det.file_laporan,
          "data_ormas_id": det.data_ormas_id
        }
        
        this.$bvModal.show('modal-form')
        this.createMode = false
        if(det.tanggal_berakhir) this.tgl_end = det.tanggal_berakhir.substring(0,10)
        
      })
    },
    addForm(){
      this.$bvModal.show('modal-form')
      this.createMode = true
      this.resetForm()
    },
    // editSengketa(d){
    //   this.$bvModal.show('modal-form')
    //   this.createMode = false
    //   this.form = {
    //     "sumber_laporan": d.sumber_laporan,
    //     "keterangan": d.keterangan,
    //     "tanggal_sengketa": d.tanggal_sengketa,
    //     "data_ormas_id": d.dataOrmas.id,
    //     "file_laporan": d.file_laporan,
    //   }
    // },
    async submit(){
      var body = null
      this.form.tanggal_sengketa = this.form.tanggal_sengketa + 'T00:00:00.000Z'
      delete this.form.tanggal_berakhir
      delete this.form.status_sengketa
      var urlpath = ''
      if(this.createMode) {
        
        body = this.form
        urlpath = 'createSengketa'
        this.$store
        .dispatch(urlpath, body)
        .then(() => {
          var context = this
          context.$bvModal.hide('modal-form')
          context.fetchData()
          Swal.fire(
                {
                  title: this.createMode ? "Data Berhasil Disimpan" : 'Data Berhasil Diubah',
                  icon: "success",
                  text: "",
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                  context.$bvModal.hide('modal-form')
                  context.fetchData()
                  Swal.close();
                }
              );
              this.resetForm()
        })
      } else{ 
        urlpath = 'patchSengketa'
        body = this.form
        this.$store
        .dispatch(urlpath, [body, this.id])
        .then(() => {
          var context = this
          context.$bvModal.hide('modal-form')
          context.$bvModal.hide('end-sengketa')
          context.fetchData()
          this.resetForm()
          Swal.fire(
                {
                  title: this.createMode ? "Data Berhasil Disimpan" : 'Data Berhasil Diubah',
                  icon: "success",
                  text: "",
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                  context.$bvModal.hide('modal-form')
                  context.fetchData()
                  Swal.close();
                }
              );
        })
      }
      
    },
    submitEndSengketa(){
        var urlpath = 'sengketaBerakhir'
        var body = {tanggal_berakhir: this.tgl_end + 'T00:00:00.000Z'}
        this.$store
        .dispatch(urlpath, [body, this.id])
        .then(() => {
          var context = this
          context.$bvModal.hide('modal-form')
          context.$bvModal.hide('end-sengketa')
          context.fetchData()
          this.resetForm()
          Swal.fire(
                {
                  title: "Sengketa Telah Berakhir",
                  icon: "success",
                  text: "",
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                  context.$bvModal.hide('modal-form')
                  context.fetchData()
                  Swal.close();
                }
              );
        })
    },
    fileClicked(){
      setTimeout(() => {
        this.$refs.fileInput.click()  
      }, 500);
    },
    uploadFile(e){
      var calculateSize = Math.floor(e.target.files[0].size / 1024)
      if(calculateSize > 15000) {
        Swal.fire({
          title: "Gagal Upload",
          text: 'Ukuran File Terlalu Besar',
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        return false
      }
      
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.$store.dispatch('uploadFileGeneral', formData).then((res) =>{

        if(res.data.status != false){
          // "path": res.data.data.newFilePath,
          Swal.fire({
            title: "Upload File Berhasil",
            text: '',
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(() => {
            this.form.file_laporan = res.data.data.newFilePath
          })
        }
        this.$refs.fileInput.value=null;
      }).catch((err) =>{
        Swal.fire({
          title: "Upload File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.$refs.fileInput.value=null;
      })
    },
    cetakSengketa(t){
      this.loadExExcel = true
      this.$store
        .dispatch("printExcelSengketa", {
          tanggal_sengketa: this.filter.tanggal_sengketa, 
          status_sengketa: this.filter.status_sengketa, 
          search: this.search,
          type: t
        })
        .then((res) => {
          this.loadExExcel = false
          if(!res.data.status){
            Swal.fire({
              title: "Export File Gagal",
              text: res.data.message,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then(() => {
            })
            return false
          }
          window.open(res.data.meta.urlPath , "_blank");
        }).catch((err) =>{
          this.loadExExcel = false
        Swal.fire({
          title: "Export File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
      })
    },
    toDetail(item){
      this.$bvModal.show('modal-detail')
      this.detail = item
    },
    deleteSengketa(id){
      const context = this
      Swal.fire({
        title: "Anda yakin ingin menghapus sengketa?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Hapus",
      }).then((result) => {
        if (result.isConfirmed) {
          context.$store.dispatch("deleteSengketa", id).then((/*res*/) => {
            Swal.fire({
              title: "Sengketa berhasil dihapus",
              text: "",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Lanjut",
            }).then(() => {
              context.fetchData()
            })
          })
        }
      }).catch(()=>{
        Swal.fire({
          title: "Sengketa gagal dihapus",
          text: '',
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Lanjut",
        }).then(() => {
        })
      });
    
    }
  },
  watch: {
    search: {
      handler() {
        // Check if the user has stopped typing
        if (this.typingTimeout) {
          clearTimeout(this.typingTimeout);
        }
        this.typingTimeout = setTimeout(() => {
          // User has stopped typing, perform your action here
          this.searchSubmit()

        }, 500); // Adjust the delay as needed (1 second in this example)
      },
      immediate: false, // Set to true if you want to trigger it immediately
    }
  }
}
</script>